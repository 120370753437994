import { salesRecoverySetAttributeUrl } from "@utils/ApiUrls";
import Axios from "axios";

export const setBasketAttribute = async(apiUrl:string,basketId: string, attributeName: string, attributeValue: string) => {
    const url = salesRecoverySetAttributeUrl(apiUrl,basketId);

    const payload = [{
        AttributeName: attributeName,
        Value: attributeValue
    }];

    try{
        await Axios.post(url, payload);
    }catch (error) {
        if (Axios.isCancel(error)) {
            console.log("Saving Basket Attribute Value Request cancelled", error.message);
            throw new Error("Cancelled");
        }
    }
}

export const setBasketAttributes = async(apiUrl:string, basketId: string, payload: any[]) => {
    const url = salesRecoverySetAttributeUrl(apiUrl, basketId);
    
    try{
        await Axios.post(url, payload);
    }catch (error) {
        if (Axios.isCancel(error)) {
            console.log("Saving Basket Attribute Value Request cancelled", error.message);
            throw new Error("Cancelled");
        }
    }
}