import Axios from 'axios'


const experianAddressSearch = async (addressToken:string, query: string) =>{
    if(addressToken == undefined || addressToken == null){
        console.log("ERROR: Failed to load address validation token!");
    }
    const headers = {
        'Auth-Token': addressToken
    };
    const data = {
        "country_iso": "NZL",
        "components": {
          "unspecified": [
            query
          ]
        },
        "location": "",
        "dataset": "datafusion"
    }
    const url = `https://api.experianaperture.io/address/search/v1`;
    if(query.trim().length > 3){
        const result = await Axios.post(url,data,{headers: headers});
        const resultData = result.data;
        if(result.status == 200){
            return resultData.result.suggestions;
        }else{
            return [];
        }
    }else{
        return [];
    }
}


const experianAddressMetadata = async (addressToken:string, key: string) =>{
    if(addressToken == undefined || addressToken == null){
        console.log("ERROR: Failed to load address validation token!");
    }
    const url = `https://api.experianaperture.io/address/format/v1/${key}`;
    const headers = {
        'Auth-Token': addressToken
    };
    const result = await Axios.get(url, {headers: headers});
    if(result.status == 200){
        const data = result.data.result;
        return data.address;
    }
}
export const AddressFinderService = {
    experianAddressSearch:experianAddressSearch,
    experianAddressMetadata:experianAddressMetadata
}