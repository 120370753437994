import moment, { Moment } from 'moment';

export const Days = (date? : Moment): {text: string, value: string}[] => {
    let days = [];
    let dayLimit = date !== undefined ? date.daysInMonth() : 31; 
    for(let i = 1; i <= dayLimit; i ++){
        days.push({
            text: i.toString().length === 1 ? `0${i.toString()}` : i.toString(),
            value: i.toString()
        })
    };

    return days;
}

export const Months = [{
    text: 'Jan',
    value: '1'
},
{
    text: 'Feb',
    value: '2'
},
{
    text: 'Mar',
    value: '3'
},
{
    text: 'Apr',
    value: '4'
},
{
    text: 'May',
    value: '5'
},
{
    text: 'Jun',
    value: '6'
},
{
    text: 'Jul',
    value: '7'
},
{
    text: 'Aug',
    value: '8'
},
{
    text: 'Sep',
    value: '9'
},
{
    text: 'Oct',
    value: '10'
},
{
    text: 'Nov',
    value: '11'
},
{
    text: 'Dec',
    value: '12'
}]

export const Years = (): {text: string, value: string}[] => {
    let years = [];
    for(let i = moment().year(); i >= moment().year() - 100; i --){
        years.push({
            text: i.toString(),
            value: i.toString()
        })
    };

    return years;
}